<template>
  <q-page class="q-py-md q-px-xl">
    <div class="row q-gutter-xl">
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Dalam Karantina</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_karantina
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Tenaga Pendidik</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_karantina_guru
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
      <q-card class="my-card">
        <q-card-section class="q-py-xs">Siswa</q-card-section>
        <q-card-section class="text-h5 text-center text-bold text-white">
          {{
          count_karantina_siswa
          }}
        </q-card-section>
        <q-card-section class="q-py-xs text-right">Orang</q-card-section>
      </q-card>
    </div>
    <div class="full-width q-py-md">
      <q-card flat bordered>
        <q-card-section class="row justify-end q-gutter-md">
          <q-select
            label="Filter by Divisi:"
            filled
            dense
            map-options
            v-model="seldiv"
            use-input
            hide-selected
            fill-input
            @input="fetchData('')"
            input-debounce="0"
            :options="filtereddiv"
            style="max-width:150px"
            @filter="filterFn"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">No results</q-item-section>
              </q-item>
            </template>
          </q-select>
          <q-input
            filled
            v-model="search"
            placeholder="Cari Nama"
            dense
            class="bg-white"
            style="border-radius: 5px; width: 350px;"
            @keypress.enter="fetchData('nama')"
          >
            <template v-slot:append>
              <q-btn
                flat
                icon="search"
                class="text-white absolute-right"
                @click="fetchData('nama')"
                style="background-color:#03913F"
              />
            </template>
          </q-input>
        </q-card-section>
        <q-markup-table bordered dense class="col-12">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;" @click="fetchData('no_induk')">No Induk</th>
              <th class="text-left" @click="fetchData('nama')">Nama</th>
              <th class="text-left" @click="fetchData('')">Jabatan</th>
              <th class="text-left" @click="fetchData('')">Divisi</th>
              <th class="text-center" @click="fetchData('jenis_kelamin')">Gender</th>
              <th class="text-center" @click="fetchData('gedung')">Gedung</th>
              <th class="text-center" @click="fetchData('no_kamar')">Kamar</th>
              <th class="text-right" @click="fetchData('nilai_survey')">Sisa hari</th>
            </tr>
          </thead>
          <tbody v-if="listData.length" class>
            <tr v-for="d in listData" :key="d.spp_id">
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">{{ d.jabatan }}</td>
              <td class="text-left">{{ d.divisi }}</td>
              <td class="text-center" style="width: 100px;">{{ d.jenis_kelamin }}</td>
              <td class="text-center" style="width: 100px;">{{ d.gedung }}</td>
              <td class="text-center" style="width: 100px;">{{ d.no_kamar }}</td>
              <td class="text-center" style="width: 100px;">{{ daysLeft(d.tgl_karantina) }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">tidak ada data</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-pagination
            v-model="activePage"
            :max="Math.ceil(count_page / 10)"
            input
            input-class="text-orange-10"
            @click="fetchData(orderBy)"
          />
        </q-card-actions>
      </q-card>
    </div>
  </q-page>
</template>

<style></style>

<script>
import moment from "moment";
export default {
  name: "Quarantine",
  data() {
    return {
      seldiv: "Semua Divisi",
      filtereddiv: [],
      listData: [],
      count_karantina: 0,
      count_karantina_guru: 0,
      count_karantina_siswa: 0,
      count_isolasi: 0,
      count_page: 0,
      orderBy: "",
      orderType: "",
      activePage: 1,
      search: ""
    };
  },
  mounted() {
    this.fetchData("no_induk");
  },
  methods: {
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.filtereddiv = this.$DIVISI.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    fetchData(order) {
      // this.orderBy = order

      // if(this.orderType == 'ASC'){
      //   this.orderType = 'DESC'
      // }
      // else{
      //   this.orderType = 'ASC'
      // }
      this.orderBy = "id";
      this.orderType = "DESC";

      var src = "%25";
      if (this.search != "") src = this.search;

      this.$http
        .get(
          "/list_karantina/" +
            src +
            "/" +
            this.orderBy +
            "/" +
            this.orderType +
            "/" +
            (this.activePage - 1) * 10 +
            "/" +
            this.seldiv,
          {}
        )
        .then(result => {
          this.listData = result.data;
        });
      this.$http
        .get("/count_list_karantina/" + src + "/" + this.seldiv, {})
        .then(result => {
          this.count_page = result.data[0].count;
        });
      this.$http.get("/jml_karantina/" + src, {}).then(result => {
        this.count_karantina = result.data.count;
      });
      this.$http.get("/jml_karantina_guru", {}).then(result => {
        this.count_karantina_guru = result.data.count;
      });
      this.$http.get("/jml_karantina_siswa", {}).then(result => {
        this.count_karantina_siswa = result.data.count;
      });

      // this.$http.get('/jml_isolasi', {})
      //   .then (result => {
      //     this.count_isolasi = result.data.count
      //   })
    },
    daysLeft(dt) {
      var admission = moment().format("YYYY-MM-DD");
      var discharge = moment(dt, "YYYY-MM-DD");
      var dayLeft = 14 + discharge.diff(admission, "days");

      if (dayLeft < 0) return 0;

      return dayLeft;
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  flex-grow: 2;
  // min-width: 250px;
  // background-color: #B9F6CA;
  background-color: #232979;
  color: #babef2;
}
.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 36px;
}
</style>
